/** Search form */
.search-form {
  @extend .row;
}

.search-form label {
  @extend .mb-3;

  font-weight: normal;
}

.search-form .search-field {
  @extend .form-control;
}

.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

/* Gravity Forms Styling */

.gform_legacy_markup_wrapper textarea.large {
  @include media-breakpoint-up(md) {
    height:150px!important;
  }
}

.gform_wrapper.gravity-theme .gfield input.large, .gform_wrapper.gravity-theme .gfield select.large, textarea {
	/*
  background: rgba(255,255,255,0.5)!important;
  border: 1px solid white!important;
  outline: none!important;
  border-radius: 3px!important;
  */
}